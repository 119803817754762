import axiosApi from '@/api/axiosApi';

export const addDrDealInfo = (data) => {
    return axiosApi({
        url: '/intserv/dr/addDrDealInfo',
        method: 'post',
        data
    })
}

export const addDrProducts = (data) => {
    return axiosApi({
        url: '/intserv/dr/addDrProducts',
        method: 'post',
        data
    })
}

export const addDrProgress = (data) => {
    return axiosApi({
        url: '/intserv/dr/addDrProgress',
        method: 'post',
        data
    })
}

export const addDrSalesTeam = (data) => {
    return axiosApi({
        url: '/intserv/dr/addDrSalesTeam',
        method: 'post',
        data
    })
}

export const addDrCompetitors = (data) => {
    return axiosApi({
        url: '/intserv/dr/addDrCompetitors',
        method: 'post',
        data
    })
}

export const updateDrDealInfoById = (data) => {
    return axiosApi({
        url: '/intserv/dr/alterDrDealInfoById',
        method: 'post',
        data
    })
}

export const deleteDrProductsById = (data) => {
    return axiosApi({
        url: '/intserv/dr/deleteDrProductsById',
        method: 'post',
        data
    })
}

export const deleteProgressById = (data) => {
    return axiosApi({
        url: '/intserv/dr/deleteProgressById',
        method: 'post',
        data
    })
}

export const deleteDrSalesTeamById = (data) => {
    return axiosApi({
        url: '/intserv/dr/deleteDrSalesTeamById',
        method: 'post',
        data
    })
}

export const deleteDrCompetitorsById = (data) => {
    return axiosApi({
        url: '/intserv/dr/deleteDrCompetitorsById',
        method: 'post',
        data
    })
}

export const getDrDealInfoById = (data) => {
    return axiosApi({
        url: `/intserv/dr/getDrDealInfoById/${data}`,
        method: 'get'
    })
}

export const getProgressByDrId = (data) => {
    return axiosApi({
        url: `/intserv/dr/getProgressByDrId/${data}`,
        method: 'get'
    })
}

export const getCompetitorsByDrId = (data) => {
    return axiosApi({
        url: `/intserv/dr/getCompetitorsByDrId/${data}`,
        method: 'get'
    })
}

export const getProjectByDrId = (data) => {
    return axiosApi({
        url: `/intserv/dr/getProjectByDrId/${data}`,
        method: 'get'
    })
}

export const getSalesTeamByDrId = (data) => {
    return axiosApi({
        url: `/intserv/dr/getSalesTeamByDrId/${data}`,
        method: 'get'
    })
}

export const getDealInfosCount = (data) => {
    return axiosApi({
        url: '/intserv/dr/getDealInfosCount',
        method: 'post',
        data
    })
}

export const pageWith = (data) => {
    return axiosApi({
        url: '/intserv/dr/pageWith',
        method: 'post',
        data
    })
}

export const getUserInfoByAccount = (data) => {
    return axiosApi({
        url: '/intserv/dr/getUserInfoByAccount',
        method: 'post',
        data
    })
}

export const getCustomRelationshipByAccount = () => {
    return axiosApi({
        url: '/intserv/dr/getCustomRelationshipByAccount',
        method: 'get'
    })
}


