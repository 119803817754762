<template>
    <div class="dr">
        <div class="t-container">
            <div class="title">{{ $i18n.t('dr.subject') }}</div>
            <div class="title-bar">{{ $i18n.t('dr.title') }}</div>
            <div class="data-bar">
                <div class="left">
                    <el-button round size="mini" style="background-color: #c7000b; color: #ffffff;" @click="toPage">Create Deal</el-button>
                </div>
                <div style="text-align: center; margin: auto; display: flex; width: 700px;">
                    <div style="margin-right: 20px; width: 130px; text-align: left; font-weight: 600; flex-shrink: 0;">Approval Status: </div>
                    <div class="data-item" @click="setApprovalStatus('Approved')">{{ $i18n.t('dr.state1') }} ({{ approved }})</div>
                    <div class="data-item" @click="setApprovalStatus('Disapproved')">{{ $i18n.t('dr.state2') }} ({{ disapproved }})</div>
                    <div class="data-item" @click="setApprovalStatus('To be Approved')">{{ $i18n.t('dr.state3') }} ({{ wait }})</div>
                </div>
            </div>
            <div class="data-bar">
                <div style="text-align: center; margin: auto; display: flex; width: 700px;">
                    <div style="margin-right: 20px; width: 130px; text-align: left; font-weight: 600; flex-shrink: 0;">DR Status: </div>
                    <div class="data-item" @click="setStatus('Closed')">{{ $i18n.t('dr.state4') }} ({{ closed }})</div>
                    <div class="data-item" @click="setStatus('Unclosed')">{{ $i18n.t('dr.state5') }} ({{ unclosed }})</div>
                    <div class="data-item" @click="setStatus('Pending')">{{ $i18n.t('dr.state6') }} ({{ pending }})</div>
                    <!-- <div class="data-item" @click="setStatus('Done')">{{ $i18n.t('dr.state7') }} ({{ done }})</div> -->
                    <div class="data-item" @click="setStatus('Cancelled')">{{ $i18n.t('dr.state8') }} ({{ cancelled }})</div>
                </div>
            </div>
            <div class="table">
                <el-table :data="tableData" height="100%" style="width: 100%" @row-click="rowClick">
                    <el-table-column  prop="projectName" :label="$i18n.t('dr.column1')" width="180"></el-table-column>
                    <el-table-column prop="companyName" :label="$i18n.t('dr.column2')" width="220"></el-table-column>
                    <el-table-column prop="expectedDate" :label="$i18n.t('dr.column3')" width="220"></el-table-column>
                    <el-table-column prop="approvalStatus" :label="$i18n.t('dr.column4')" width="180"></el-table-column>
                    <el-table-column prop="status" :label="$i18n.t('dr.column5')" width="100"></el-table-column>
                    <el-table-column prop="validUntil" :label="$i18n.t('dr.column6')" width="200"></el-table-column>
                    <el-table-column prop="endCustomersName" :label="$i18n.t('dr.column7')" width="220"></el-table-column>
                </el-table>
            </div>
            <div class="flex-end">
                <el-pagination background layout="prev, pager, next" :current-page.sync="pageParam.current" :page-count="Number(pages)" @current-change="getList"/>
            </div>
        </div>
    </div>
</template>

<script>
import {getDealInfosCount, pageWith, getUserInfoByAccount} from '@/api/dr'
 export default {
    name: '',
    data() {
        return {
            tableData: [],
            total: 0,
            pages: 0,
            pageParam: {
                'current': 1,
                'extra': {},
                'order': 'descending',
                'size': 10,
                'sort': 'publishDate',
                'model': {
                    applicant: '',
                    status: '',
                    approvalStatus: ''
                }
            },
            approved: 0,
            closed: 0,
            disapproved: 0,
            unclosed: 0,
            wait: 0,
            pending: 0,
            done: 0,
            cancelled: 0
        }
    },
    async created() {
        await this.checkAuthority()
    },
    async mounted() {
        // console.log(this.$store.state.client.user)
        if (this.$store.state.client.user.account) {
            this.pageParam.model.applicant = this.$store.state.client.user.account
            this.pageParam.model.approvalStatus = 'To be Approved'
            if ( await this.getUserInfoByAccount() ) {
                this.getDealInfosCount()
                this.getList()
            }
        }
    },
    methods: {
        async checkAuthority() {
            if (!this.$store.state.client.user.account) {
                this.$message.error({message: 'Please log in', duration: 5000})
                this.$router.replace({
                    path: `/login`
                })
                return false;
            }
        },
        async getUserInfoByAccount() {
            const {data: {data}} = await getUserInfoByAccount({email: this.$store.state.client.user.account})
            if (data === null) {
                this.$notify({
                    title: 'Tips',
                    message: 'Please contact dr@holowits.com to associate your company if you did not associate before.',
                    duration: 0
                })
                let backUrl = document.referrer.split('/').slice(3).join('/')
                console.log(backUrl);
                if (backUrl === 'dr-list') {
                    this.$router.push({
                        path: `/home`
                    })
                } else {
                    this.$router.replace({
                        path: `/${backUrl}`
                    })
                }
                return false;
            } else {
                return true;
            }
        },
        rowClick(row, column, event) {
            this.$router.push({
                path: `/deal/${row.id}`
            })
        },
        setApprovalStatus(value) {
            this.pageParam.model.approvalStatus = value
            this.pageParam.model.status = ''
            this.getList()
        },
        setStatus(value) {
            this.pageParam.model.approvalStatus = ''
            this.pageParam.model.status = value
            this.getList()
        },
        // 状态统计
        async getDealInfosCount() {
            if (!this.$store.state.client.user.id) {
                this.$message.error('Please log in to your company account (administrator account).')
            } else {
                const {data: {data, isSuccess}} = await getDealInfosCount({
                    applicant: this.$store.state.client.user.account
                })
                if (isSuccess) {
                    if (data != null) {
                        this.approved = data.approved
                        this.closed = data.closed
                        this.disapproved = data.disapproved
                        this.unclosed = data.unclosed
                        this.wait = data.wait
                        this.pending = data.pending
                        this.done = data.done
                        this.cancelled = data.cancelled
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },

        // 验证是否有权限进此页面，如果没有权限则返回主页面
        check() {

        },
        // 获取数据
        async getList() {
            const {data: {data, isSuccess}} = await pageWith(this.pageParam)
            if (isSuccess) {
                this.tableData = data.records
                this.pages = data.pages
            }
        },
        // 跳转到详情页
        toPage() {
            this.$router.push({
                path: '/deal'
            })
        }
    }
 }
</script>

<style lang="scss" scoped>
.dr {
    background-color: #F5F7FA;
}
.t-container {
    margin: auto;
    // padding-top: 30px;
    width: 1400px;
    min-height: 100vh;
    background-color: #ffffff;
}

.title {
    // color: #d2333c;
    font-size: 22px;
    border-top: 3px solid #d2333c;
    width: 92px;
}

.title-bar {
    margin-top: 10px;
    padding-left: 10px;
    color: #ffffff;
    font-size: 22px;
    background-color: #333333;
}

.data-bar {
    margin: 8px 0;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;

    .left {
        position: absolute;
        left: 0;
        top: -4px;
    }

    .data-item {
        width: 130px;
        flex-shrink: 0;
        position: relative;
        padding-bottom: 2px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        &:hover {
            border-bottom: 1px solid #000000;
        }
        & + .data-item {
            margin-left: 30px;
            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                background-color: #dbdbdb;
                top: 50%;
                left: -15px;
                margin-top: -10px;
            }
        }
    }
}

.table {
    height: 600px;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}


.t-card-bar {
    display: flex;

    .t-card {
        color: #666666;
        &+.t-card {
            margin-left: 20px;
        }
        height: 100px;
        // width: 340px;
        border: 1px solid #666666;
        border-radius: 6px;
        flex: 1;
        cursor: pointer;
        .t-card-body {
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 10px;
            .t-card-label {
                font-size: 16px;
                font-weight: 600;
                height: 40px;
                line-height: 40px;
            }
            .t-card-value {
                font-size: 18px;
                font-weight: 600;
                height: 40px;
                line-height: 40px;
            }
        }
    }
}
.t-list-bar {
    margin-top: 30px;
    .t-list-header {
        height: 50px;
        background-color: #333333;
        display: flex;
        flex-wrap: nowrap;
        .t-list-header-title {
            color: #dbdbdb;
            font-size: 16px;
            height: 100%;
            line-height: 50px;
            padding: 0 10px;
            flex-shrink: 0;
            position: relative;
            text-align: center;
            &+.t-list-header-title::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                background-color: #dbdbdb;
                top: 50%;
                left: 0px;
                margin-top: -10px;
            }
            &:nth-child(1) {
                width: 200px;
            }
            &:nth-child(2) {
                width: 240px;
            }
            &:nth-child(3) {
                width: 200px;
            }
            &:nth-child(4) {
                width: 150px;
            }
            &:nth-child(5) {
                width: 100px;
            }
            &:nth-child(6) {
                width: 196px;
            }
        }
    }
}
</style>